import { useEffect, useRef, useState } from "react";
import { runKinematics } from "./kinematics";

import background from "./background.jpg";
import dove from "../dove.png";
import me from "./me.png";
import "./index.css";

export function SendLetter({ onNext }) {
  const [letterPosition, setLetterPosition] = useState(null);
  const [freeBird, setFreeBird] = useState(false);

  const root = useRef();
  const letter = useRef();
  const doveRef = useRef();

  useEffect(() => runKinematics(root.current), []);

  useEffect(() => {
    if (letterPosition !== "dove") {
      return;
    }
    function loop() {
      const { top, bottom, left } = doveRef.current.getBoundingClientRect();
      letter.current.style.transform = `translate(${left}px, ${
        top + (bottom - top) / 2
      }px)`;
      if (left > 0) {
        requestAnimationFrame(loop);
      } else {
        setFreeBird(false);
        onNext();
      }
    }

    requestAnimationFrame(loop);
  }, [letterPosition]);

  return (
    <div
      ref={root}
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        cursor: letterPosition === "hand" ? "grabbing" : "grab",
      }}
      onMouseMove={(event) => {
        if (letterPosition === "hand") {
          letter.current.style.left = 0;
          letter.current.style.top = 0;
          letter.current.style["pointer-events"] = "none";
          letter.current.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
          if (event.clientY < root.current.offsetHeight / 3) {
            setFreeBird(true);
          }
        }
      }}
    >
      <img
        src={background}
        style={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          height: "100%",
          pointerEvents: "none",
          zIndex: -1,
        }}
      />
      <div
        ref={letter}
        style={{
          position: "absolute",
          bottom: "10%",
          left: "50%",
          transform: "translate(50%, 0)",
          width: 200,
          height: 100,
          padding: 20,
          border: "2px solid black",
          boxSizing: "border-box",
          background: "white",
          fontSize: 18,
          fontFamily: "Garamond, serif",
        }}
        onMouseMove={() => {
          setLetterPosition("hand");
        }}
      >
        Für Lea
      </div>
      <img
        src={me}
        style={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          height: "100%",
          pointerEvents: "none",
          zIndex: 1,
        }}
      />
      <img
        ref={doveRef}
        src={dove}
        className="dove"
        onMouseMove={() => {
          if (letterPosition === "hand") {
            setLetterPosition("dove");
          }
        }}
        style={{ animationPlayState: freeBird ? "revert" : "paused" }}
      />
    </div>
  );
}
