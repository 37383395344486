import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import dove from "../dove.png";
import teardrop from "./teardrop.mp3";

function Letter() {
  return (
    <div
      style={{
        display: "block-block",
        maxWidth: 500,
        width: "100%",
        margin: "0 auto",
        padding: 20,
        fontFamily: "'Kalam', cursive",
        background: `url(https://images.unsplash.com/photo-1586075010923-2dd4570fb338)`,
        backgroundSize: "contain",
        color: "#16264c",
      }}
    >
      <p>Meine liebe Lea,</p>
      <p>
        ich wünsche dir alles alles Gute und Liebe zum Geburtstag. Das letzte
        Lebensjahr war ein besonders herausforderndes, mit vielen Veränderungen,
        doch du hast deinen Kopf nicht dabei verloren, soweit ich das als
        Neuzugang beurteilen kann. Was ich beurteilen kann: Ich liebe deinen
        Kopf wie ich ihn kenne und wie er sich wandelt.
      </p>
      <p>
        Ganz doll hoffe ich, dass das neue Jahr entspannter für dich wird und du
        mehr Zeit für die Dinge findest die du mit ganzem Herzen machen willst.
        Und natürlich freue ich mich auch immer mehr dabei zu sein, zeitlich wie
        auch, naja, spirituell! Du bist ein sehr aufregendes Wesen mit magischer
        Flusen-Energie und ich will mit dir zaubern. So jetzt weißt du es.
      </p>
      <p>
        Zum Geburtstag schenke ich dir einen gemeinsamen Opernbesuch. Wenn ich
        mich recht entsinne, warst du früher öfter dort aber nun seit einer
        Weile nicht mehr. Ich widerum war noch nie in einer Oper und wäre
        neugierig das mit dir zu erleben. Wenn du denkst, dass Berlin auch gute
        Vorstellungen zu bieten haben könnte, dann würde ich dich an dem Abend
        auch gerne noch zu einem meiner Lieblingsrestaurants ausführen. Aber für
        Hamburg hätte ich sonst auch Ideen.
      </p>
      <p>
        Liebe & Freude an dir und uns,
        <br />
        Dein Gregor
      </p>
    </div>
  );
}

const loader = new Loader({
  apiKey: "AIzaSyC2CsXFKmiw7JcJjRlcupjuEmVgw_NQ6r8",
  version: "weekly",
});

const Lea = { lat: 52.548071, lng: 13.3656858 };

export function FlyHome() {
  const [map, setMap] = useState(null);
  const [angle, setAngle] = useState(0);
  const [arrived, setArrived] = useState(false);
  const mapElRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    loader.load().then(() => {
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapElRef.current, {
        center: { lat: 28.8734459, lng: -13.8221955 },
        zoom: 8,
        disableDefaultUI: true,
        gestureHandling: "none",
        zoomControl: false,
        mapTypeId: "hybrid",
        labels: true,
      });
      setMap(map);
    });
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (!map || arrived) {
      return;
    }

    let effecting = true;
    function loop() {
      if (!effecting || arrived) {
        return;
      }

      const F = 40;
      const lat = map.center.lat();
      const lng = map.center.lng();

      if (audio.paused) {
        audio.play();
      }

      const distance = Math.abs(Lea.lat - lat) + Math.abs(Lea.lng - lng);
      audio.volume = Math.max(0.1, 1 - distance / 40);
      console.log(distance);
      if (distance < 0.5) {
        setArrived(true);
      }
      map.setCenter({
        lat: lat + -Math.sin(angle) / F,
        lng: lng + Math.cos(angle) / F,
      });
      requestAnimationFrame(loop);
    }

    requestAnimationFrame(loop);

    return () => {
      effecting = false;
    };
  }, [arrived, map, angle]);

  return (
    <div
      style={{ width: "100vw", height: "100vh", cursor: "crosshair" }}
      onMouseMove={(event) => {
        const { target } = event;
        if (arrived) {
          return;
        }
        setAngle(
          Math.round(
            Math.atan2(
              event.clientY - target.offsetHeight / 2,
              event.clientX - target.offsetWidth / 2
            ) * 100
          ) / 100
        );
      }}
    >
      <audio src={teardrop} loop ref={audioRef} />
      {map && (
        <img
          src={dove}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1,
            margin: "50vh 36% 0",
            transform: `translateY(-50%) scale(0.2) rotate(${
              angle - Math.PI
            }rad)`,
          }}
        />
      )}
      <div ref={mapElRef} style={{ width: "100vw", height: "100vh" }} />
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 0,
          right: 0,
          zIndex: 3,
          transform: arrived
            ? "translateY(0%)"
            : "translateY(calc(-100% - 10px))",
          transition: "transform 500ms ease-in-out",
        }}
      >
        <Letter />
      </div>
    </div>
  );
}
