import React, { useEffect, useState } from "react";
import { SendLetter } from "./1-send";
import { FlyHome } from "./2-fly";
import feels from "./feels.mp3";
import "./app.css";

const TIME_OF_RELEASE = new Date("Feb 9 2021 00:00:00 GMT+1");
const getSecondsToRelease = () => (TIME_OF_RELEASE - new Date()) / 1000;

export function App() {
  const [stage, setStage] = useState(0);
  const [secondsToRelease, setSecondsToRelease] = useState(
    getSecondsToRelease()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // can't just subtract 1 each step as interval gets throttled for inactive tabs
      const diff = getSecondsToRelease();
      setSecondsToRelease(diff);
      if (diff <= 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isBirthday = secondsToRelease <= 0;

  useEffect(() => {
    document.title = isBirthday ? "Alles Gute Baby!" : "Not quite yet";
  }, [isBirthday]);

  return [
    <button
      onClick={() => setStage(1)}
      className={"play " + (isBirthday ? "ready" : "")}
      disabled={!isBirthday}
    >
      {isBirthday
        ? "Auspacken"
        : new Date(1000 * secondsToRelease).toISOString().substr(11, 8)}
    </button>,
    <>
      <audio src={feels} autoPlay loop />
      <SendLetter onNext={() => setStage(2)} />
    </>,
    <>
      <FlyHome onNext={() => setStage(3)} />
    </>,
  ][stage];
}
