import * as Raphael from "raphael";
import { $M, $V } from "./sylvester";

export function runKinematics(main) {
  const width = main.offsetWidth;
  const height = main.offsetHeight;

  const baseX = (width / 2) * 1.1;
  const baseY = height * 0.92;
  const jointW = width / 3.5;
  const jointH = width / 12;

  const paper = Raphael(main, width, height);

  const svg = main.childNodes[0];
  const { left, top } = svg.getBoundingClientRect();

  const joints = [...Array(2)].map((_, i) =>
    paper
      .rect(
        baseX - jointW * i * 2,
        baseY - jointH / 2,
        jointW,
        jointH * (i === 0 ? 1 : 0.8),
        jointH / 2
      )
      .attr({
        fill: "#8c7776",
        stroke: "transparent",
      })
      .data("rotation", 0)
  );

  updatePosesForward();

  function getEndPos() {
    let totalR = 0;
    for (let i = 0; i < joints.length; i++) {
      totalR += (joints[i].data("rotation") * Math.PI) / 180;
    }
    const endEl = joints[joints.length - 1];
    return [
      endEl.attr("x") + jointW * Math.cos(totalR),
      endEl.attr("y") + jointW * Math.sin(totalR) + jointH / 2,
    ];
  }

  /* Forward kinematics */
  function updatePosesForward() {
    let totalRotation = 0;

    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i];

      let prevX, prevY;
      if (i > 0) {
        prevX = joints[i - 1].attr("x");
        prevY = joints[i - 1].attr("y");
      } else {
        prevX = baseX - jointW;
        prevY = baseY;
      }

      prevX += jointW * Math.cos(totalRotation);
      prevY += jointW * Math.sin(totalRotation);

      joint.transform("");
      joint.attr({
        x: prevX,
        y: prevY,
      });

      totalRotation += (joints[i].data("rotation") * Math.PI) / 180;

      joint.rotate((totalRotation * 180) / Math.PI, prevX, prevY + jointH / 2);
    }
  }

  /* Inverse kinematics
      =========================================================================*/

  function makeJacobian() {
    const dx = [];
    const dy = [];

    const [endX, endY] = getEndPos();

    for (const el of joints) {
      const elX = el.attr("x");
      const elY = el.attr("y");

      const rotationAxis = $V([0, 0, 1]);
      const jointPosition = $V([endX - elX, endY - elY, 0]);

      const entry = rotationAxis.cross(jointPosition);

      dx.push(entry.e(1));
      dy.push(entry.e(2));
    }

    return $M([dx, dy]);
  }

  svg.addEventListener("mousemove", function (evt) {
    const x = evt.pageX - left;
    const y = evt.pageY - top;

    const [endX, endY] = getEndPos();

    let e = $V([endX - x, endY - y]);
    if (Math.sqrt(Math.pow(endX - x, 2) + Math.pow(endY - y, 2)) > jointW / 2) {
      e = e.toUnitVector().x(jointW / 2);
    }

    const jacobian = makeJacobian();
    const jje = jacobian.x(jacobian.transpose().x(e));
    const alpha = e.dot(jje) / jje.dot(jje);
    const d = jacobian.transpose().x(e).x(alpha);

    for (let i = 0; i < joints.length; i++) {
      const r = joints[i].data("rotation");

      joints[i].data("rotation", r - (d.e(i + 1) * 180) / Math.PI);
    }

    updatePosesForward();
  });

  return () => {
    paper.remove();
  };
}
